import { render, staticRenderFns } from "./FoodLogTable.vue?vue&type=template&id=0881bc7b&scoped=true&"
import script from "./FoodLogTable.vue?vue&type=script&lang=js&"
export * from "./FoodLogTable.vue?vue&type=script&lang=js&"
import style0 from "./FoodLogTable.vue?vue&type=style&index=0&lang=css&"
import style1 from "./FoodLogTable.vue?vue&type=style&index=1&id=0881bc7b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0881bc7b",
  null
  
)

export default component.exports