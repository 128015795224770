<template>
    <div class="container-fluid container-wrapper mt-4 mb-3">
        <div class="icon mb-3">
            <img src="/img/logo_new.svg" alt="" height="40px">
        </div>
        <div class="weight-log-header-wrapper ml-1 mr-1 mb-3">
            <div class="weight-log-header-heading mb-3">
                <h1 class="logs-heading">Food Logs</h1>
            </div>
        </div>

        <log-card :data="data" :data-request="dataRequest" source="pdf"></log-card>

        <div class="weight-logs-table pt-2">
            <food-log-table :data="data.meals" source="pdf"></food-log-table>
        </div>
    </div>
</template>

<script>
import LogCard from "@/components/LogCard";
import FoodLogTable from "@/views/Components/Logs/FoodLogs/FoodLogTable";

export default {
    components: {
        FoodLogTable,
        LogCard
    },
    props: ['data', 'dataRequest'],

}
</script>

<style scoped>

.container-wrapper {
    padding: 50px
}

.logs-heading {
    font-size: 40px !important;
    color: black!important;
}

.weight-log-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.timeline-wrapper {
    background-color: #F6F9FC;
    border-radius: 10px;
}

.icon {
    margin-left: 50px;
}

.weight-log-header-wrapper, .timeline-wrapper, .weight-logs-table {
    width: 1416px!important;
    margin-left: 50px!important;
}

.primary-header, .sub-header {
    display: block;
}

.primary-header {
    font-size: 18px;
    color: black;
}

.sub-header {
    color: #a7a7a7;
}
</style>
