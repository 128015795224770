<template>
    <div class="container-fluid mt-4 mb-2" ref="content">
        <!-- Top Navigation -->
        <div>
          <back-button navigate-to="NutritionReport" />
        </div>

        <!-- Top Navigation Finished-->

        <div class="log-header-wrapper ml-1 mr-1 mb-3">
            <div class="log-header-heading">
                <h1>Food Logs</h1>
            </div>
            <div class="log-header-buttons">
                <base-button class="btn-secondary-action" @click="printLogs">Print</base-button>
                <base-button class="btn-secondary-action" @click="downloadLogs">Download</base-button>
                <base-input class="d-inline-block ml-2  pipelines_dropdown input_height_30">
                    <el-select
                        class="select-primary pagination-select"
                        placeholder="Pipelines"
                        v-model="foodLogRequest.duration"
                        @change="changeFilter"
                    >
                        <el-option
                            v-for="duration in dropdown"
                            :key="duration"
                            :label="duration"
                            :value="duration"
                        >
                        </el-option>
                    </el-select>
                </base-input>
            </div>
        </div>

        <div v-loading="loaders.loading">
            <log-card :data="logCard" :data-request="foodLogRequest" source="view"></log-card>
        </div>
        <div v-if="patientLocale!='en'" style="display: flex; align-items: end; justify-content: end; padding: 0rem 0.25rem">
          <span @click="showTranslatedFoodLogs" style="cursor: pointer"><i class="fa fa-globe" aria-hidden="true"></i> See Translation</span>
        </div>
        <div class="px-1 pt-2" v-loading="loaders.loading || loaders.dataLoading">
            <food-log-table :data="logs.foodLogs" :loading="loaders.loading" source="view"></food-log-table>
        </div>

        <infinite-loader :loader="loaders.scrollLoader" :data-loading-finished="scroll.dataLoaded" />

        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :preview-modal="false"
            :enable-download="true"
            filename="FoodLog"
            :pdf-quality="2"
            :manual-pagination="false"
            :paginate-elements-by-height="4000"
            pdf-format="a3"
            :html-to-pdf-options="htmlToPdfOptions"
            pdf-orientation="landscape"
            pdf-content-width="70%"
            @beforeDownload="beforeDownload"
            @hasDownloaded="hasDownloaded"
            ref="html2Pdf"
        >
            <section slot="pdf-content">
                <food-log-pdf id="foodLogPdf" ref="foodLogPdf" :data="logCard" :data-request="foodLogRequest"></food-log-pdf>
            </section>

        </vue-html2pdf>

    </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import FoodLogTable from "@/views/Components/Logs/FoodLogs/FoodLogTable";
import FoodLogPdf from "@/views/Components/Logs/FoodLogs/FoodLogPdf";
import LogCard from "@/components/LogCard";
import InfiniteLoader from "@/components/Loaders/InfiniteLoader";
import BackButton from "@/components/Router/BackButton";
import FoodLogStyles from "@/views/Components/Logs/FoodLogs/FoodLogStyles";
import moment from "moment";
import googleTranslateMixin from "../../../../mixins/googleTranslateMixin";

export default {
    name: "FoodLog",
    components: {
        FoodLogPdf,
        FoodLogTable,
        VueHtml2pdf,
        LogCard,
        InfiniteLoader,
        BackButton
    },
    mixins:[googleTranslateMixin],
    data() {
        return {
            loaders: {
              loading: false,
              scrollLoader: false,
              dataLoading: false,
            },
            scroll: {
              dataLoaded: false,
              foodLogsLength: 0,
            },
            logs: {
              foodLogs: [],
              pdfLogs: [],
            },
            patientLocale:'en',
          showTranslatedFoodLog: false,
            patient_id: this.$route.params.id,
            foodLogRequest: {
                page: 1,
                duration: '30 days',
                source: 'view',
            },
            dropdown: ['7 days', '30 days', '60 days', '90 days', '6 Month Excel', '12 Month Excel'],
            logCard: {
                patient: {
                    first_name: '',
                    last_name: '',
                },
                duration: {
                    from: '',
                    to: '',
                }
            },
            htmlToPdfOptions: {

              filename: `FoodLog.pdf`,
              margin: [0.2,0],

              image: {
                type: 'jpeg',
                quality: 2
              },

              enableLinks: false,
              pagebreak: { avoid: ['tr'] },
              html2canvas: {
                scale: 1,
                useCORS: true,
              },

              jsPDF: {
                unit: 'in',
                format: 'a3',
                orientation: 'landscape'
              }
            }
        }
    },
    methods: {
        /**
         * Print the food logs
         */
        printLogs() {
          this.logCard['meals'] = {...this.logs.foodLogs};
          this.$nextTick(function (){
            const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0')
            let prtHtml = document.getElementById('foodLogPdf').innerHTML
            WinPrint.document.write
            (`<!DOCTYPE html>
            <html>
                <head>`
              + FoodLogStyles.style +
              `</head>
                <body>
                    ${prtHtml}
                </body>
            </html>`
            )
            WinPrint.document.close()
            WinPrint.focus()
            WinPrint.print()
          })
        },

        downloadLogs() {
            if (this.foodLogRequest.duration === '6 Month Excel' || this.foodLogRequest.duration === '12 Month Excel') {
                this.$notify.error({
                    title: 'Food Logs Duration',
                    message: 'Duration must be in days for PDF download.',
                });
                return;
            }
            this.foodLogRequest.source = 'pdf'
            this.getFoodLogs(false);
        },
        changeFilter(filterVal) {
            if (filterVal === '6 Month Excel' || filterVal === '12 Month Excel') {
                // download excel
              const duration = filterVal.split(" ")
                this.foodLogsExcel(duration[0]);
            } else {
                // get Api table & pdf.
                this.scroll.dataLoaded = false;
                this.scroll.foodLogsLength = 0;
                this.foodLogRequest.page = 1;
                this.getFoodLogs(false)
            }
        },

        beforeDownload() {
            this.loaders.loading = true;
            this.loaders.dataLoading = true;
        },

        hasDownloaded() {
            this.loaders.loading = false;
        },

      async getFoodLogs(fromScroll) {
        let vm = this;
        if (fromScroll === false && vm.foodLogRequest.source !== 'pdf') { // upon change of filter.
          vm.loaders.loading = true;
          vm.loaders.dataLoading = true;
          vm.logs.foodLogs = [];
        } else if (fromScroll === true && vm.foodLogRequest.source !== 'pdf') { // upon scroll.
          vm.loaders.scrollLoader = true;
        } else if (vm.foodLogRequest.source === 'pdf') {
          vm.loaders.loading = true;
          vm.loaders.dataLoading = true;
        }
        let duration = vm.foodLogRequest.duration.split(' ')[0];
        let response = null;
        try {
          response = await axios.get(`${this.$store.getters.getBaseUrl}/api/food-logs?patient_id=${this.patient_id}&duration=${duration}&page=${this.foodLogRequest.page}&source=${this.foodLogRequest.source}`)
          vm.logCard = response.data.data;
          vm.patientLocale = response.data.data.locale;
          if (vm.foodLogRequest.source === 'pdf') {
            vm.logs.pdfLogs = response.data.data.meals;
            this.htmlToPdfOptions.filename = `${this.logCard.patient.first_name}'s ${this.foodLogRequest.duration} food Logs of ${moment()}.pdf`
            this.$refs.html2Pdf.generatePdf()
          } else {
            let dataMeals = {...response.data.data.meals};
            if(vm.showTranslatedFoodLog){
              await this.translateMain(dataMeals);
            }
            if (!fromScroll) {
              vm.logs.foodLogs = {...dataMeals}
            } else {
              vm.logs.foodLogs = {...vm.logs.foodLogs, ...dataMeals}
            }
          }

          let responseCount = Object.keys(vm.logs.foodLogs).length;
          if (responseCount === vm.scroll.foodLogsLength && vm.foodLogRequest.source === 'view') {
            vm.scroll.dataLoaded = true;
          }
          vm.scroll.foodLogsLength = Object.keys(vm.logs.foodLogs).length;

          vm.loaders.loading = false;
          vm.loaders.dataLoading = false;
          vm.loaders.scrollLoader = false;
          vm.foodLogRequest.source = 'view';
        } catch (Error) {
          vm.loaders.loading = false;
          vm.loaders.dataLoading = false;
          vm.loaders.scrollLoader = false;
          vm.foodLogRequest.source = 'view';
        }
      },

      async showTranslatedFoodLogs() {
        if (!this.showTranslatedFoodLog) {
          this.loaders.dataLoading = true;
          this.showTranslatedFoodLog = true;
          let dataMeals = this.logs.foodLogs;
          if (this.showTranslatedFoodLog) {
            await this.translateMain(dataMeals);
          }
          this.logs.foodLogs = {...dataMeals}
          this.loaders.dataLoading = false;
        }
      },

        foodLogsExcel(months) {
            let vm = this;
            vm.loaders.loading = true;
            const config = {
                responseType: "blob"
            };
            axios.post(`${this.$store.getters.getBaseUrl}/api/food-logs/excel`,
                {
                    patient_id: this.patient_id,
                  months
                },
                config,
            ).then(response => {
                vm.logCard.duration.from = moment().subtract(6, 'months').format('MMMM DD, YYYY')
                vm.logCard.duration.to = moment().format('MMMM DD, YYYY')
                const url = URL.createObjectURL(new Blob([response.data], {
                    type: 'application/vnd.ms-excel'
                }))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `${vm.logCard.patient.first_name} ${vm.logCard.patient.last_name}'s ${moment()} food-logs.xlsx`)
                document.body.appendChild(link)
                link.click()
            }).catch(error => {
            }).finally(() => {
                vm.loaders.loading = false;
            });
        },

        handleScroll() {
          if (this.scroll.dataLoaded === false && this.loaders.scrollLoader === false) {
            let scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
            let scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
            let scrolledToBottom = (scrollTop + window.innerHeight) >= scrollHeight - 100;
            if (scrolledToBottom) {
              this.foodLogRequest.page += 1;
              this.getFoodLogs(true);
            }
          }
        },

        /**
         * Get Food sections
         */
        getFoodSections() {
          let vm = this
          vm.loaders.loading = true
          vm.$store.dispatch('_getMealSections', {
            id: this.patient_id,
          }).finally(() => {
            vm.loaders.loading = false
          })
        }
    },
    mounted: function () {
        this.getFoodLogs(false)
        if (!this.$store.state.foodTypes.length) this.getFoodSections() //Condition to check and get if food types not exists
        document.addEventListener('scroll', this.handleScroll)
    },

    beforeDestroy: function () {
        document.removeEventListener('scroll', this.handleScroll)
    },
}
</script>

<style scoped>
.log-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.btn-primary-action {
    background-color: #94B527 !important;
    border: none;
}
</style>
