<template>
    <div class="timeline-wrapper row ml-1 mb-3 mr-1 pr-3 pl-3 pt-2 pb-4 pt-4" :class="source==='view' ? 'log-card-box-shadow' : ''">
        <div class="col">
            <span class="sub-header">Name</span>
            <span class="primary-header font-weight-600">{{ getName }}</span>
        </div>
    </div>
</template>

<script>

export default {
    props: ['data', 'dataRequest', 'source'],
    name: "LogCard",
    computed: {
        getName() {
            return this.data.patient.first_name + ' ' + this.data.patient.last_name
        },
        getReportDuration() {
            return this.dataRequest.duration
        }
    },
    methods: {
    }
}
</script>

<style scoped>
.timeline-wrapper {
    background-color: white;
    border-radius: 10px;
}

.log-card-box-shadow {
  box-shadow: 0 10px 10px #eaeaea;
}

.primary-header, .sub-header {
    display: block;
}

.primary-header {
    font-size: 18px;
    color: black;
}

.sub-header {
    color: #a7a7a7;
}

</style>
