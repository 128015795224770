<template>
    <div class="table-wrapper">
        <div v-if="data">
            <div v-if="Object.keys(data).length > 0">
                <div v-width-aware-table :class="{'table-card' : source != 'pdf', 'card' : source != 'pdf'}" v-for="(logs, key, index) in data" :key="key">
                    <table class="table table-borderless food-logs-table">
                        <thead>
                        <tr class="table-header-row table-responsive-lg">
                            <th scope="col" colspan="1">{{ getFormattedDate(key) }}</th>
                            <th scope="col" colspan="1">Time</th>
                            <th scope="col" colspan="1">Brand Name</th>
                            <th scope="col" colspan="1">Serving Size</th>
                            <th scope="col" colspan="1">Calories</th>
                            <th scope="col" colspan="1">Carb</th>
                            <th scope="col" colspan="1">Protein</th>
                            <th scope="col" colspan="1">Sodium</th>
                            <th scope="col" colspan="1">Fat</th>
                            <th scope="col" colspan="1">Sugar</th>
                            <th scope="col" colspan="1">Fiber</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="(type, typeIndex) in $store.state.foodTypes">
                            <template v-if="type.title!=='exercise' && type.title!=='waters' && logs[type.title]">
                                <tr class="food-logs-heading-row" :class="`section${typeIndex+1}`" :key="type.title">
                                    <th class="text-capitalize" scope="col" colspan="11">{{ type.title }}</th>
                                </tr>
                                <template v-for="(item) in logs[type.title]">
                                    <tr class="food-logs-row" :key="item.id">
                                      <th v-width-aware-table-heading scope="col" colspan="1" class="text-capitalize">
                                          <span class="width-285 item-name">
                                              {{ item.item_name }}
                                          </span>
                                      </th>
                                      <th scope="col" colspan="1"><span class="width-15">{{ item.meal_time }}</span></th>
                                      <th scope="col" colspan="1">
                                        <span class="width-150 item-name">
                                              {{ item.brand_name }}
                                        </span>
                                      </th>
                                      <th scope="col" colspan="1">
                                        <span class="width-120 item-name">
                                          {{ servingSize(item.nf_serving_size_qty, item.user_serving_Size) }}
                                          {{ item.nf_serving_size_unit }}
                                        </span>
                                      </th>
                                      <th scope="col" colspan="1"><span class="width-15">{{ calcCalories(item, index, 0) }}</span></th>
                                      <th scope="col" colspan="1"><span class="width-15">{{ calcCarbohydrate(item, index, 1) }}</span></th>
                                      <th scope="col" colspan="1"><span class="width-15">{{ calcProtein(item, index, 2) }}</span></th>
                                      <th scope="col" colspan="1"><span class="width-15">{{ calcSodium(item, index, 3) }}</span></th>
                                      <th scope="col" colspan="1"><span class="width-15">{{ calcFat(item, index, 4) }}</span></th>
                                      <th scope="col" colspan="1"><span class="width-15">{{ calcSugar(item, index, 5) }}</span></th>
                                      <th scope="col" colspan="1"><span class="width-15">{{ calcFiber(item, index, 6) }}</span></th>
                                    </tr>
                                </template>
                            </template>
                        </template>

                        <tr class="total">
                            <th scope="col" colspan="4">Total</th>
                            <th scope="col" v-for="iterator in 7" :key="index + '' +iterator">{{
                                    itemsTotal[index][iterator - 1]
                                }}
                            </th>
                        </tr>

                        <template v-if="logs.hasOwnProperty('exercise') && logs.exercise.length > 0">
                            <tr class="food-logs-heading-row exercise">
                                <th scope="col" colspan="11">Exercise</th>
                            </tr>
                            <template v-for="(item) in logs.exercise">
                                <tr class="food-logs-heading-row" :key="item.id">
                                    <th scope="col" class="text-capitalize">{{ item.Description }}, {{ item.Minutes }}
                                        minutes
                                    </th>
                                    <th scope="col" colspan="10"><span>{{ parseInt(item.CaloriesBurned) }}</span><span class="cal-loss">{{' (Calories Burned)'}}</span></th>
                                </tr>
                            </template>
                        </template>

                        <template v-if="logs.hasOwnProperty('waters') && logs.waters.length > 0">
                            <tr class="food-logs-heading-row water">
                                <th scope="col">Water</th>
                                <th scope="col" colspan="10" class="water-value">{{ parseInt(logs.waters[0].total) }} Oz</th>
                            </tr>
                        </template>

                        </tbody>
                    </table>

                </div>
            </div>
            <div v-else>
                <card class="no-data">
                    <div v-if="loading===true">
                      <h3 class="text-center">Fetching..</h3>
                    </div>
                    <div v-else>
                      <h3 class="text-center">No data for given number of days</h3>
                    </div>
                </card>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";

export default {
    name: "FoodLogTable",
    props: ['data', 'source', 'loading'],
    data() {
        return {
            itemsTotal: []
        }
    },
    methods: {
        /**
         * Return the serving size according to specified conditions
         * @param quantityServing
         * @param userServing
         */
        servingSize(quantityServing, userServing) {
          if (quantityServing && !isNaN(quantityServing)) {
            if (quantityServing == 1) {
              return userServing && !isNaN(userServing) ? this.parseAndGetServingValue(userServing) : 1
            } else {
              return this.parseAndGetServingValue(quantityServing)
            }
          } else {
            return 1
          }
        },

        /**
         * Parse and get serving value with round decimal
         * @param number
         * @returns {string|number}
         */
        parseAndGetServingValue(number) {
          const numberStr = number.toString()
          const decimalPart = numberStr.split('.')[1]
          const result = decimalPart ? decimalPart.length > 2 : false
          if (result) {
            return parseFloat(number).toFixed(2)
          } else {
            return parseFloat(number)
          }
        },

        /**
         * Check if needs to be fixed to decimal points
         * @param serving
         * @returns {*|string}
         */
        checkDecimal(serving) {
          serving = parseFloat(serving) == parseInt(serving) ? serving : parseFloat(serving).toFixed(0)
          return isNaN(serving) ? 0 : serving
        },

        getFormattedDate(date) {
            return moment(date).format('MMMM DD, YYYY')
        },

        getValue(item, servingSize) {
          return (item == '' ? 0 : item) * (servingSize == '' ? 1 : servingSize)
        },

        calcCalories(item, index, innerIndex) {
            let value = this.getValue(item.nf_calories, item.user_serving_Size);
            this.calcTotal(value,index,innerIndex);
            return this.checkDecimal(value);
        },

        calcCarbohydrate(item, index, innerIndex) {
            let value = this.getValue(item.nf_total_carbohydrate, item.user_serving_Size);
            this.calcTotal(value,index,innerIndex);
            return this.checkDecimal(value);
        },
        calcProtein(item, index, innerIndex) {
            let value = this.getValue(item.nf_protein, item.user_serving_Size);
            this.calcTotal(value,index,innerIndex);
            return this.checkDecimal(value);
        },
        calcSodium(item, index, innerIndex) {
            let value = this.getValue(item.nf_sodium, item.user_serving_Size);
            this.calcTotal(value,index,innerIndex);
            return this.checkDecimal(value);
        },
        calcFat(item, index, innerIndex) {
            let value = this.getValue(item.nf_total_fat, item.user_serving_Size);
            this.calcTotal(value,index,innerIndex);
            return this.checkDecimal(value);
        },
        calcSugar(item, index, innerIndex) {
            let value = this.getValue(item.nf_sugars, item.user_serving_Size);
            this.calcTotal(value,index,innerIndex);
            return this.checkDecimal(value);
        },
        calcFiber(item, index, innerIndex) {
            let value = this.getValue(item.nf_dietary_fiber, item.user_serving_Size);
            this.calcTotal(value,index,innerIndex);
            return this.checkDecimal(value);
        },
      calcTotal(value,index,innerIndex){
        if(!isNaN(value)){
          this.itemsTotal[index][innerIndex] = this.itemsTotal[index][innerIndex] + value;
          this.itemsTotal[index][innerIndex] = parseFloat(this.checkDecimal(this.itemsTotal[index][innerIndex]));
        }
      }
    },
    watch: {
        data(newVal) {
            let length = Object.keys(newVal).length;
            this.itemsTotal = new Array(length).fill(0).map(() => new Array(7).fill(0));
        }
    },
    directives: {
        widthAwareTable: {
            inserted: function (element) {
                let table = element.firstChild;
                if (table.offsetWidth > element.offsetWidth) {
                    table.className = 'table table-borderless food-logs-table table-responsive breaker';
                }
            }
        },
        widthAwareTableHeading: {
            inserted: function (element) {
                // console.log(element.offsetWidth)
                // let width = element.offsetWidth;
                // let itemName = element.firstChild;
                // itemName.style.width = width
            }
        }
    }
}
</script>
<style>
.item-name {
  display: block;
  white-space: initial;
}
</style>
<style scoped>
.no-data {
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    flex-direction: row;
}

.table-header-row th {
    background-color: #F6F9FC !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    color: black;
    text-transform: capitalize;
    font: normal normal bold 12px/17px Open Sans;
    letter-spacing: 0.48px;
}

.food-logs-heading-row {
    padding: 5px !important;
    width: 100% !important;
    background: rgba(111, 116, 143, 0.05) 0 0 no-repeat padding-box;
    color: #000000;
}

.food-logs-row th {
    font: normal normal 600 12px/17px Open Sans;
    background: white;
    letter-spacing: 0.48px;
    color: #000000;
}

.section1 th {
  background: rgb(240, 242, 253) 0 0 no-repeat padding-box !important;
  color: #5E72E4 !important;
}

.section2 th {
  background: rgb(255, 247, 238) 0 0 no-repeat padding-box !important;
  color: #FE8C19 !important;
}

.section3 th {
  background: rgb(253, 248, 240) 0 0 no-repeat padding-box !important;
  color: #ECAF52 !important;
}

.section4 th {
  background: rgb(240, 249, 253) 0 0 no-repeat padding-box !important;
  color: #58c0e4 !important;
}

.section5 th {
  background: rgb(255, 243, 238) 0 0 no-repeat padding-box !important;
  color: #FF6421 !important;
}

.section6 th {
  background: rgb(249, 252, 241) 0 0 no-repeat padding-box !important;
  color: #94B527 !important;
}

.section7 th {
  background: rgb(249, 244, 247) 0 0 no-repeat padding-box !important;
  color: #8e5576 !important;
}

.section8 th {
  background: rgb(250, 247, 243) 0 0 no-repeat padding-box !important;
  color: #D1B490 !important;
}

.section9 th {
  background: rgb(244, 249, 249) 0 0 no-repeat padding-box !important;
  color: #508484 !important;
}

.section10 th {
  background: rgb(248, 248, 245) 0 0 no-repeat padding-box !important;
  color: #A3A380 !important;
}

.exercise th {
    background: rgba(65, 163, 123, 0.05) 0 0 no-repeat padding-box !important;
    color: #41A37B !important;
}

.water th {
    background: rgba(44, 139, 254, 0.05) 0 0 no-repeat padding-box !important;
    color: #2C8BFE !important;
}

.water th .water-value {
    color: #000000 !important;
}

.total th {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    background: #E7E9ED 0 0 no-repeat padding-box !important;
}

.total th:first-child {
    font: normal normal bold 15px/20px Open Sans;
    letter-spacing: 0px;
    color: #000000;
}

.total th:not(:first-child) {
    font: normal normal bold 12px/17px Open Sans;
    letter-spacing: 0.48px;
    color: #000000;
}

.net-change {
    text-align: right;
}

.net-status {
    color: #F5365C !important;
}

.water-value {
    color: #172B4D !important;
    font: normal normal 600 12px/17px Open Sans;
}

.cal-loss{
  color: #F5365C;
  font-size: 10px;
}
.table-card{
  overflow: auto !important;
}
.table tr th,.table tr td{
  word-wrap: break-word;
}
</style>
