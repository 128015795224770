export default {
  style: `<style>
                      body {
                        margin-left: 50px;
                        margin-right: 50px
                      }

                      .primary-header, .sub-header {
                        display: block;
                      }

                      .primary-header {
                        font-size: 18px;
                        color: black;
                        margin: 3px 0 10px 0;
                      }

                      .sub-header {
                        color: #a7a7a7;
                      }

                      .logs-heading {
                        font-size: 40px !important;
                        color: black !important;
                      }

                      td {
                        padding: 5px;
                      }

                      table, th, td {
                        border: 0.5px solid #E6E6E6;
                        border-collapse: collapse;
                        width: 100% !important;
                      }

                      th {
                        font-size: 12px;
                        padding: 8px;
                        width: 100% !important;
                      }

                      .weight-log-header-wrapper {
                        display: flex;
                        justify-content: space-between;
                      }

                      .total {
                        margin-top: 30px !important;
                      }

                      .no-data {
                        height: 400px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        justify-items: center;
                        flex-direction: row;
                      }

                      .table-header-row th {
                        background-color: #F6F9FC !important;
                        padding-top: 20px !important;
                        padding-bottom: 20px !important;
                        color: black;
                        text-transform: capitalize;
                        font: normal normal bold 12px/17px Open Sans;
                        letter-spacing: 0.48px;
                      }

                      .food-logs-heading-row {
                        padding: 5px !important;
                        width: 100% !important;
                        background: rgba(111, 116, 143, 0.05) 0 0 no-repeat padding-box;
                        color: #000000;
                      }

                      .text-capitalize {
                        text-transform: capitalize !important;
                      }

                      .food-logs-row th {
                        font: normal normal 600 12px/17px Open Sans;
                        letter-spacing: 0.48px;
                        color: #000000;
                      }

                      .breakfast th {
                        background: rgba(94, 114, 228, 0.05) 0 0 no-repeat padding-box !important;
                        color: #5E72E4 !important;
                      }

                      .lunch th {
                        background: rgba(254, 140, 25, 0.05) 0 0 no-repeat padding-box !important;
                        color: #FE8C19 !important;
                      }

                      .dinner th {
                        background: rgba(255, 100, 33, 0.05) 0 0 no-repeat padding-box !important;
                        color: #FF6421 !important;
                      }

                      .snacks th {
                        background: rgba(255, 205, 22, 0.05) 0 0 no-repeat padding-box !important;
                        color: #FFCD16 !important;
                      }

                      .exercise th {
                        background: rgba(65, 163, 123, 0.05) 0 0 no-repeat padding-box !important;
                        color: #41A37B !important;
                      }

                      .water th {
                        background: rgba(44, 139, 254, 0.05) 0 0 no-repeat padding-box !important;
                        color: #2C8BFE;
                        padding-top: 5px !important;
                      }

                      .water th .water-value {
                        color: #000000 !important;

                      }

                      .total th {
                        padding-top: 20px !important;
                        padding-bottom: 20px !important;
                        background: #E7E9ED 0 0 no-repeat padding-box !important;
                      }

                      .total th:first-child {
                        font: normal normal bold 15px/20px Open Sans;
                        letter-spacing: 0px;
                        color: #000000;
                      }

                      .total th:not(:first-child) {
                        font: normal normal bold 12px/17px Open Sans;
                        letter-spacing: 0.48px;
                        color: #000000;
                      }

                      .net-change {
                        text-align: right;
                      }

                      .net-status {
                        color: #F5365C !important;
                      }

                      .water-value {
                        color: #172B4D !important;
                        font: normal normal 600 12px/17px Open Sans;
                      }

                      .item-name {
                        display: block;
                        padding: 0 5px 0 0;
                      }

                      .cal-loss{
                        color: #F5365C;
                        font-size: 10px;
                      }

                    </style>`
}
