<template>
  <div v-if="!dataLoadingFinished">
    <div v-loading="loader" class="infinite-loader position-relative w-100"></div>
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: ['loader', 'dataLoadingFinished'],
  data() {
    return {

    }
  }
}
</script>

<style scoped>
.infinite-loader {
  height: 60px;
}
</style>
