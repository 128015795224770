
export default {
  methods: {
    async translateMain(tableData) {
      let strArr = [];
      if (Object.keys(tableData).length > 0) {
        for (let key in tableData) {
          if (tableData.hasOwnProperty(key)) { // Check if the property is an own property, not inherited
            for (let key2 in tableData[key]) {
              for (let key3 in tableData[key][key2]) {
                strArr.push(tableData[key][key2][key3].item_name)
              }
            }
          }
        }
        let i = 0;
        let response = await this.translate(strArr);
        await response.json().then(data => {
          if (data && data.data && data.data.translations && data.data.translations.length > 0) {
            if (Object.keys(tableData).length > 0) {
              for (let key in tableData) {
                if (tableData.hasOwnProperty(key)) { // Check if the property is an own property, not inherited
                  for (let key2 in tableData[key]) {
                    for (let key3 in tableData[key][key2]) {
                      if (data.data.translations[i] && data.data.translations[i].detectedSourceLanguage != 'en') {
                        tableData[key][key2][key3].item_name = data.data.translations[i].translatedText
                      }
                      i++;
                    }
                  }
                }
              }
            } else {
              return "Translation not available"
            }
          }
        })
      }
      return tableData;
    },

    translate(content) {
      const apiKey = 'AIzaSyA6hMQ5nJA3Q0DRzBEQ0XNPTIjzttLPeq4';
      const apiUrl = 'https://translation.googleapis.com/language/translate/v2';
      const requestData = {
        q: content,
        target: 'en',
      };
      // const requestUrl = `${apiUrl}?key=${apiKey}&q=${encodeURIComponent(params)}&source=es&target=en`;
      const requestUrl = `${apiUrl}?key=${apiKey}`;

      // Make a GET request to the Translation API
      return new Promise((resolve, reject) => {
        fetch(requestUrl, {
          method: 'POST',
          body: JSON.stringify(requestData),
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error);
          });
      })
    },
  }
}
